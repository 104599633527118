import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import PageItem from "../components/pageItem"
import SEO from "../components/seo"
import Img from "gatsby-image"

const SecondPage = (props) => (
  <Layout pageInfo={{ pageName: "aqua" }}>
    <SEO title="aqua" />
    {/* <h1>Ather</h1> */}
    {/* <p>Welcome to page 2</p> */}
    {/* <Link to="/">Go back to the homepage</Link> */}
    <div id="content" className="pages-placeholder  horizontal-scroll-wrapper" 
    style={{width: `3400vw`, background: "#5C697A"}}
    >
      {/* <div className="pages"> */}

        <PageItem  ><Img fluid={props.data.aqua01.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua02.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua03.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua04.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua05.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua06.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua07.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua08.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua09.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua10.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua11.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua12.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua13.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua14.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua15.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua16.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua17.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua18.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua19.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua20.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua21.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua22.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua23.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua24.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua25.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua26.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua27.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua28.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aqua29.childImageSharp.fluid} /></PageItem>

{/* <div>item 1</div>
  <div>item 2</div>
  <div>item 3</div>
  <div>item 4</div>
  <div>item 5</div>
  <div>item 6</div>
  <div>item 7</div>
  <div>item 8</div> */}










        {/* <div className="page 01">page01</div>
      <div className="page 02">page02</div> */}
      {/* </div> */}
    </div>
  </Layout>
)

export default SecondPage

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    aqua01: file(relativePath: { eq: "aqua_1_1.jpg" }) {
      ...fluidImage
    }
    aqua02: file(relativePath: { eq: "aqua_1_2.jpg" }) {
      ...fluidImage
    }
    aqua03: file(relativePath: { eq: "aqua_1_3.jpg" }) {
      ...fluidImage
    }
    aqua04: file(relativePath: { eq: "aqua_1_4.jpg" }) {
      ...fluidImage
    }
    aqua05: file(relativePath: { eq: "aqua_1_5.jpg" }) {
      ...fluidImage
    }
    aqua06: file(relativePath: { eq: "aqua_1_6.jpg" }) {
      ...fluidImage
    }
    aqua07: file(relativePath: { eq: "aqua_1_7.jpg" }) {
      ...fluidImage
    }
    aqua08: file(relativePath: { eq: "aqua_1_8.jpg" }) {
      ...fluidImage
    }
    aqua09: file(relativePath: { eq: "aqua_1_9.jpg" }) {
      ...fluidImage
    }
    aqua10: file(relativePath: { eq: "aqua_1_10.jpg" }) {
      ...fluidImage
    }
    aqua11: file(relativePath: { eq: "aqua_1_11.jpg" }) {
      ...fluidImage
    }
    aqua12: file(relativePath: { eq: "aqua_1_12.jpg" }) {
      ...fluidImage
    }
    aqua13: file(relativePath: { eq: "aqua_1_13.jpg" }) {
      ...fluidImage
    }
    aqua14: file(relativePath: { eq: "aqua_1_14.jpg" }) {
      ...fluidImage
    }
    aqua15: file(relativePath: { eq: "aqua_1_15.jpg" }) {
      ...fluidImage
    }
    aqua16: file(relativePath: { eq: "aqua_1_16.jpg" }) {
      ...fluidImage
    }
    aqua17: file(relativePath: { eq: "aqua_1_17.jpg" }) {
      ...fluidImage
    }
    aqua18: file(relativePath: { eq: "aqua_1_18.jpg" }) {
      ...fluidImage
    }
    aqua19: file(relativePath: { eq: "aqua_1_19.jpg" }) {
      ...fluidImage
    }
    aqua20: file(relativePath: { eq: "aqua_1_20.jpg" }) {
      ...fluidImage
    }
    aqua21: file(relativePath: { eq: "aqua_1_21.jpg" }) {
      ...fluidImage
    }
    aqua22: file(relativePath: { eq: "aqua_1_22.jpg" }) {
      ...fluidImage
    }
    aqua23: file(relativePath: { eq: "aqua_1_23.jpg" }) {
      ...fluidImage
    }
    aqua24: file(relativePath: { eq: "aqua_1_24.jpg" }) {
      ...fluidImage
    }
    aqua25: file(relativePath: { eq: "aqua_1_25.jpg" }) {
      ...fluidImage
    }
    aqua26: file(relativePath: { eq: "aqua_1_26.jpg" }) {
      ...fluidImage
    }
    aqua27: file(relativePath: { eq: "aqua_1_27.jpg" }) {
      ...fluidImage
    }
    aqua28: file(relativePath: { eq: "aqua_1_28.jpg" }) {
      ...fluidImage
    }
    aqua29: file(relativePath: { eq: "aqua_1_29.jpg" }) {
      ...fluidImage
    }

  }
`